exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-templates-poem-js": () => import("./../../../src/templates/poem.js" /* webpackChunkName: "component---src-templates-poem-js" */),
  "component---src-templates-poems-js": () => import("./../../../src/templates/poems.js" /* webpackChunkName: "component---src-templates-poems-js" */),
  "component---src-templates-poet-js": () => import("./../../../src/templates/poet.js" /* webpackChunkName: "component---src-templates-poet-js" */),
  "component---src-templates-poets-js": () => import("./../../../src/templates/poets.js" /* webpackChunkName: "component---src-templates-poets-js" */)
}

